
import { computed, ComputedRef, defineComponent } from "vue";
import Label from "../../../shared/components/Label.vue";
import Translation from "../../../shared/components/Translation.vue";
import TranslationAlt from "../../../shared/components/TranslationAlt.vue";
import PopularCollections from "../../../shared/components/PopularCollections.vue";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import { useRoute } from "vue-router";
import { MyCollectionPageDto, useMyCollectionPagesResource } from "@kunsten/core";

export default defineComponent({
    name: "MyCollectionsNotLogged",
    components: { Label, PopularCollections, Translation, TranslationAlt, SpinnerContainer },
    setup() {
        const route = useRoute();

        const { entities, loading } = useMyCollectionPagesResource({
            page: "1",
        });

        const entity: ComputedRef<MyCollectionPageDto | null> = computed(() => {
            if (entities.value?.length) return entities.value[0];
            return null;
        });

        return {
            loading,
            entity,
            route,
        };
    },
});
