import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6662fa2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-collections-not-logged" }
const _hoisted_2 = {
  key: 0,
  class: "my-collections-not-logged-page-content"
}
const _hoisted_3 = { class: "my-collections-not-logged-page-content-left" }
const _hoisted_4 = { class: "my-collections-not-logged-page-content-figure" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "my-collections-not-logged-page-content-right" }
const _hoisted_8 = { class: "my-collections-actions pt-6 has-text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Label = _resolveComponent("Label")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!
  const _component_PopularCollections = _resolveComponent("PopularCollections")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.entity?.picture)
                ? (_openBlock(), _createElementBlock("figure", _hoisted_5, [
                    _createElementVNode("img", {
                      src: _ctx.entity?.picture + '/download',
                      alt: ""
                    }, null, 8, _hoisted_6)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Label, {
              size: "bg",
              subSize: "md"
            }, {
              title: _withCtx(() => [
                _createElementVNode("h1", null, [
                  _createVNode(_component_TranslationAlt, {
                    data: _ctx.entity,
                    property: "title"
                  }, null, 8, ["data"])
                ])
              ]),
              subtitle: _withCtx(() => [
                _createVNode(_component_Translation, {
                  data: _ctx.entity,
                  property: "description"
                }, null, 8, ["data"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_link, {
                class: "btn gossamer is-size-5 py-4 px-5",
                to: { name: 'LandingPage', query: { view: 'login', redirect: _ctx.route.fullPath } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("collection.my-collections-login")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 })),
    _createVNode(_component_PopularCollections)
  ]))
}