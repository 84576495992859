<template>
    <div class="collections-top py-6">
        <div class="page-container">
            <IconText size="md">
                <template v-slot:icon>
                    <a role="button" @click="goBack" :aria-label="$t('general.back')">
                        <i class="fas fa-long-arrow-alt-left has-text-white"></i>
                        <span class="is-hidden">{{ $t("general.back") }}</span>
                    </a>
                </template>
                <template v-slot:text>
                    <a @click="goBack" @keydown.enter="goBack" tabindex="0">
                        {{ $t("general.back") }}
                    </a>
                </template>
            </IconText>
            <div class="collections-top-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import IconText from "../../../shared/components/IconText.vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "CollectionsTop",
    components: { IconText },
    setup() {
        const router = useRouter();

        const goBack = () => {
            router.back();
        };

        return { goBack };
    },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.collections-top {
    border-top: 1px solid $kun-scroll;
    background-color: $kun-dark-alt;
    a {
        color: $kun-white-alt;
    }
}
</style>
